.dashboard-page {
  padding: var(--space-6);
}

.page-header {
  margin-bottom: var(--space-6);
}

.page-header h1 {
  font-size: 2rem;
  color: #ffffff;
  margin-bottom: var(--space-3);
}

.page-header p {
  color: #b0b0b9;
}

.stat-card {
  background-color: #2c2c3c;
  border: 1px solid #4b4b63;
  border-radius: var(--radius-md);
  padding: var(--space-4);
}

.stat-card h3 {
  color: #b0b0b9;
  font-size: 0.875rem;
  margin-bottom: var(--space-2);
}

.recent-activity {
  background-color: #2c2c3c;
  border: 1px solid #4b4b63;
  border-radius: var(--radius-md);
  padding: var(--space-4);
}

.recent-activity h2 {
  font-size: 1.25rem;
  margin-bottom: var(--space-4);
  color: #ffffff;
}

.activity-item {
  display: flex;
  align-items: center;
  padding: var(--space-3);
  border-bottom: 1px solid #4b4b63;
}

.activity-time {
  color: #b0b0b9;
  font-size: 0.875rem;
  margin-right: var(--space-4);
  min-width: 60px;
}

.activity-text {
  color: #ffffff;
}

.sidebar {
  width: 250px;
  background-color: #2c2c3c;
  border-right: 1px solid #4b4b63;
  padding: var(--space-4);
  height: 100vh;
  position: fixed;
}

.sidebar-header {
  padding: var(--space-4) 0;
  margin-bottom: var(--space-4);
  border-bottom: 1px solid #4b4b63;
}

.sidebar-header h2 {
  color: #ffffff;
  font-size: 1.5rem;
}

.sidebar-nav {
  display: flex;
  flex-direction: column;
  gap: var(--space-2);
}

.nav-item {
  color: #b0b0b9;
  padding: var(--space-3);
  border-radius: var(--radius-md);
  transition: var(--transition-normal);
  text-decoration: none;
}

.nav-item:hover {
  background-color: #3b3b4f;
  color: #ffffff;
}

.sidebar-footer {
  padding: var(--space-2);
  border-top: 1px solid #4b4b63;
  display: flex;
  flex-direction: column;
  gap: var(--space-3);
  margin-top: var(--space-4);
}

.user-email {
  color: #b0b0b9;
  font-size: 0.875rem;
}

.logout-btn {
  background-color: transparent;
  border: 1px solid #4b4b63;
  color: #ffffff;
  padding: var(--space-2) var(--space-4);
  border-radius: var(--radius-md);
  transition: var(--transition-normal);
}

.logout-btn:hover {
  background-color: #3b3b4f;
}


.models-grid {
display: grid;
grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
gap: var(--space-4);
}

.model-card {
background-color: #2c2c3c;
border: 1px solid #4b4b63;
border-radius: var(--radius-md);
padding: var(--space-4);
transition: var(--transition-normal);
}

.model-card:hover {
transform: translateY(-2px);
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.model-header {
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: var(--space-3);
}

.model-header h3 {
color: #ffffff;
font-size: 1.25rem;
}

.model-status {
padding: var(--space-1) var(--space-2);
border-radius: var(--radius-sm);
font-size: 0.875rem;
}

.model-status.success {
background-color: var(--success);
color: #ffffff;
}

.model-status.training {
background-color: var(--warning);
color: var(--primary);
}

.model-description {
color: #b0b0b9;
margin-bottom: var(--space-3);
}

.model-stats {
display: flex;
justify-content: space-between;
color: #b0b0b9;
font-size: 0.875rem;
}

.nav-item.active {
background-color: #3b3b4f;
color: #ffffff;
}

.sidebar-logo {
display: flex;
align-items: center;
gap: 8px;
padding: 0 8px;
}

.sidebar-logo img {
width: 32px;
height: 32px;
border-radius: 6px;
object-fit: cover;
}

.sidebar-logo h2 {
color: #ffffff;
font-size: 1.25rem;
margin: 0;
font-weight: 600;
}

/* Make sure the header has proper spacing */
.sidebar-header {
padding: var(--space-4) var(--space-2);
margin-bottom: var(--space-4);
border-bottom: 1px solid #4b4b63;
}

.header-with-button {
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: var(--space-4);
}

.upload-btn {
background-color: var(--primary-light);
color: #ffffff;
border: none;
border-radius: var(--radius-md);
padding: var(--space-2) var(--space-4);
font-size: 1rem;
font-weight: 600;
cursor: pointer;
transition: var(--transition-normal);
}

.upload-btn:hover {
background-color: var(--primary-hover);
transform: translateY(-1px);
}

.datasets-table {
background-color: #2c2c3c;
border-radius: var(--radius-lg);
border: 1px solid #4b4b63;
padding: var(--space-4);
overflow-x: auto;
}

.datasets-table table {
width: 100%;
border-collapse: collapse;
color: #ffffff;
}

.datasets-table th {
text-align: left;
padding: var(--space-3);
border-bottom: 1px solid #4b4b63;
color: #b0b0b9;
font-weight: 600;
}

.datasets-table td {
padding: var(--space-3);
border-bottom: 1px solid #4b4b63;
}

.datasets-table tr:last-child td {
border-bottom: none;
}

.datasets-table tr:hover {
background-color: rgba(75, 75, 99, 0.3);
}

.header-text {
text-align: left;
}

.header-text h1 {
margin-bottom: var(--space-2);
}

.header-text p {
color: var(--text-light);
}

.action-card {
background: var(--background-light);
border: 1px solid var(--border-color);
border-radius: 8px;
padding: 1.5rem;
cursor: pointer;
position: relative;
transition: all 0.2s ease;
}

.action-card:hover {
background: var(--background-hover);
border-color: var(--border-hover);
}

.action-card:hover .arrow-icon {
color: var(--text-primary);
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--space-4);
  margin-bottom: var(--space-6);
}

.stat-card {
background: var(--background);
border: 1px solid var(--border-color);
border-radius: var(--radius-md);
padding: 1.5rem;
cursor: pointer;
position: relative;
transition: all var(--transition-fast);
}

.stat-card:hover {
border-color: var(--primary-light);
}

.stat-card h3 {
font-size: 1.1rem;
margin-bottom: 0.5rem;
color: var(--text);
}


.stat-number {
  font-size: 3.5rem;
  font-weight: 800;
  color: var(--primary-light);
  margin: var(--space-2) 0;
  background: linear-gradient(135deg, var(--primary-light), #8b93f4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 10px rgba(125, 134, 243, 0.2);
}

.action-card {
background: var(--background);
border: 1px solid var(--border-color);
border-radius: var(--radius-md);
padding: 1.5rem;
cursor: pointer;
position: relative;
transition: all var(--transition-fast);
}

.action-card:hover {
border-color: var(--primary-light);
}

.action-content {
  flex: 1;
  text-align: left;
}

.action-content h3 {
  color: #ffffff;
  font-size: 1.25rem;
  margin-bottom: var(--space-2);
}

.action-content p {
  color: #b0b0b9;
  font-size: 0.875rem;
  line-height: 1.4;
}

.arrow-icon {
  margin-top: auto;
  align-self: flex-end;
  color: #b0b0b9;
  font-size: 1.2rem;
}

.action-card:hover .arrow-icon {
color: var(--text);
}

.stat-box {
  background: #2c2c3c;
  border: 1px solid #4b4b63;
  border-radius: var(--radius-md);
  padding: var(--space-4);
  cursor: pointer;
  transition: all var(--transition-fast);
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--space-3);
}

.stat-box:hover {
  border-color: var(--primary-light);
  transform: translateY(-1px);
}

.stat-box:hover .arrow-icon {
  color: var(--primary-light);
}

.stat-box h3 {
  font-size: 1.1rem;
  color: #ffffff;
  text-align: center;
}

.stat-box h2 {
  font-size: 2.2rem;
  color: #ffffff;
  text-align: center;
  margin: 0;
}

.action-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.action-box:hover .arrow-icon {
color: var(--primary-light);
}

.fine-tune-form {
max-width: 800px;
background: #2c2c3c;
border: 1px solid #4b4b63;
border-radius: var(--radius-md);
padding: var(--space-4);
margin: var(--space-4) auto 0;
}

.form-section {
margin-bottom: var(--space-4);
}

.form-section h3 {
color: #ffffff;
margin-bottom: var(--space-2);
}

.model-select,
.parameter-input {
width: 100%;
max-width: 300px;
padding: 8px 12px;
border: 1px solid #4b4b63;
border-radius: var(--radius-sm);
background: #1f1f2e;
color: #ffffff;
margin-top: var(--space-2);
}

.upload-btn {
padding: 8px 16px;
background: #1f1f2e;
border: 1px dashed #4b4b63;
border-radius: var(--radius-sm);
color: #b0b0b9;
cursor: pointer;
transition: all var(--transition-fast);
}

.upload-btn:hover {
border-color: var(--primary-light);
color: #ffffff;
}

.parameter-group {
margin-bottom: var(--space-3);
}

.parameter-group label {
display: block;
color: #b0b0b9;
margin-bottom: var(--space-1);
}

.next-btn {
background: var(--primary-light);
color: #ffffff;
padding: 10px 24px;
border: none;
border-radius: var(--radius-sm);
cursor: pointer;
transition: all var(--transition-fast);
width: auto;
min-width: 120px;
margin-left: auto;
margin-top: 20px;
border-radius: var(--radius-lg);
}

.next-btn:hover {
background: var(--primary-hover);
}

.parameter-slider {
width: 100%;
height: 4px;
-webkit-appearance: none;
background: #1f1f2e;
border-radius: 2px;
outline: none;
opacity: 0.7;
transition: opacity var(--transition-fast);
}

.parameter-slider:hover {
opacity: 1;
}

.parameter-slider::-webkit-slider-thumb {
-webkit-appearance: none;
width: 16px;
height: 16px;
border-radius: 50%;
background: var(--primary-light);
cursor: pointer;
transition: all var(--transition-fast);
}

.parameter-slider::-moz-range-thumb {
width: 16px;
height: 16px;
border-radius: 50%;
background: var(--primary-light);
cursor: pointer;
border: none;
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--space-6);
  margin-bottom: var(--space-6);
}

.section-label {
  display: block;
  font-size: 0.875rem;
  color: #b0b0b9;
  margin-bottom: var(--space-2);
}

.parameter-row {
  display: flex;
  flex-direction: column;
  gap: var(--space-2);
  margin-bottom: var(--space-3);
}

.parameter-row span {
  color: #ffffff;
  font-size: 0.875rem;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--z-modal);
}

.popup-content {
  background: #2c2c3c;
  border: 1px solid #4b4b63;
  border-radius: var(--radius-lg);
  padding: var(--space-6);
  text-align: center;
  animation: slideIn 0.3s ease;
}

.popup-content h3 {
  color: #ffffff;
  margin-bottom: var(--space-2);
}

.popup-content p {
  color: #b0b0b9;
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.model-details-tabs {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

.model-details-tabs button {
  padding: 0.5rem 1rem;
  border: none;
  background: none;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.model-details-tabs button.active {
  border-bottom: 2px solid var(--primary-color);
}

.details-section {
  background: var(--card-bg);
  padding: 1.5rem;
  border-radius: var(--radius-lg);
  margin-bottom: 1.5rem;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.info-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.info-item label {
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.code-block {
  background: var(--code-bg);
  padding: 1rem;
  border-radius: var(--radius-md);
  position: relative;
}

.code-block button {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.weights-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}

.weight-format {
  background: var(--card-bg);
  padding: 1rem;
  border-radius: var(--radius-md);
  text-align: center;
}

.download-btn {
  margin-top: 1rem;
  width: 100%;
  padding: 0.5rem;
  background: var(--primary-color);
  border: none;
  border-radius: var(--radius-sm);
  color: white;
  cursor: pointer;
}

.persona-textarea {
  width: 100%;
  min-height: 150px;
  padding: 12px;
  border: 1px solid #4b4b63;
  border-radius: var(--radius-md);
  font-size: 14px;
  resize: vertical;
  background-color: #1f1f2e;
  color: #ffffff;
  transition: border-color var(--transition-normal);
}

.persona-textarea:focus {
  outline: none;
  border-color: var(--primary-light);
  box-shadow: 0 0 0 2px rgba(125, 134, 243, 0.1);
}

.persona-textarea::placeholder {
  color: #b0b0b9;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-top: 1rem;
}

.info-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.info-item label {
  font-weight: 500;
  color: #666;
}

.info-item span {
  font-size: 1.1rem;
}

.dataset-viewer {
  background: #1e1e2d;
  border-radius: 8px;
  margin-top: 20px;
  border: 1px solid #4b4b63;
}

.dataset-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  background: #2c2c3c;
  border-bottom: 1px solid #4b4b63;
  border-radius: 8px 8px 0 0;
}

.dataset-header span {
  font-size: 14px;
  color: #b0b0b9;
}

.copy-btn {
  background: #3b82f6;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.2s;
}

.copy-btn:hover {
  background: #2563eb;
}

.dataset-content {
  padding: 20px;
  margin: 0;
  max-height: 500px;
  overflow-y: auto;
  font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', monospace;
  font-size: 13px;
  line-height: 1.5;
  color: #e0e0e0;
  white-space: pre-wrap;
  text-align: left;
}

.dataset-content::-webkit-scrollbar {
  width: 8px;
}

.dataset-content::-webkit-scrollbar-track {
  background: #1e1e2d;
}

.dataset-content::-webkit-scrollbar-thumb {
  background: #4b4b63;
  border-radius: 4px;
}

.dataset-content::-webkit-scrollbar-thumb:hover {
  background: #5d5d7a;
}

.format-section {
  margin-top: var(--space-4);
}

.format-inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--space-3);
  margin-bottom: var(--space-3);
}

.format-textarea {
  width: 100%;
  min-height: 60px; /* Reduced from 100px */
  padding: var(--space-2);
  background: #1f1f2e;
  border: 1px solid #4b4b63;
  border-radius: var(--radius-sm);
  color: #ffffff;
  resize: vertical;
  font-size: 14px;
  transition: border-color var(--transition-normal);
}

.format-textarea:focus {
  outline: none;
  border-color: var(--primary-light);
  box-shadow: 0 0 0 2px rgba(125, 134, 243, 0.1);
}

.format-example {
  background: #2c2c3c;
  border: 1px solid #4b4b63;
  border-radius: var(--radius-sm);
  padding: var(--space-3);
  margin-bottom: var(--space-3);
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  gap: var(--space-3);
  align-items: start;
}

.format-example-input,
.format-example-output {
  background: #1f1f2e;
  border: 1px solid #4b4b63;
  border-radius: var(--radius-sm);
  padding: var(--space-2);
  color: #ffffff;
  font-size: 14px;
  min-height: 50px;
  resize: vertical;
  width: 100%;
}

.remove-example-btn {
  padding: var(--space-2);
  background: transparent;
  border: 1px solid #ff4444;
  border-radius: var(--radius-sm);
  color: #ff4444;
  cursor: pointer;
  transition: all var(--transition-fast);
  height: fit-content;
}

.remove-example-btn:hover {
  background: #ff4444;
  color: white;
}

.add-example-btn {
  width: 100%;
  padding: var(--space-2);
  background: transparent;
  border: 1px solid var(--primary-light);
  border-radius: var(--radius-sm);
  color: var(--primary-light);
  cursor: pointer;
  transition: all var(--transition-fast);
  margin-top: var(--space-2);
}

.add-example-btn:hover {
  background: var(--primary-light);
  color: white;
}

.datasets-upload-area {
  background: #1f1f2e;
  border: 2px dashed #4b4b63;
  border-radius: var(--radius-md);
  padding: var(--space-4);
  text-align: center;
  cursor: pointer;
  transition: all var(--transition-normal);
  position: relative;
  overflow: hidden;
}

.datasets-upload-area:hover {
  border-color: var(--primary-light);
  background: #2c2c3c;
}

.datasets-upload-area input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.upload-icon {
  color: var(--primary-light);
  font-size: 2rem;
  margin-bottom: var(--space-2);
}

.upload-text {
  color: #b0b0b9;
  font-size: 0.9rem;
  margin-bottom: var(--space-2);
}

.upload-hint {
  color: #666;
  font-size: 0.8rem;
}

.file-list {
  margin-top: var(--space-3);
  display: flex;
  flex-direction: column;
  gap: var(--space-2);
}

.file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #2c2c3c;
  padding: var(--space-2) var(--space-3);
  border-radius: var(--radius-sm);
  border: 1px solid #4b4b63;
}

.file-name {
  color: #ffffff;
  font-size: 0.8rem;
}

.remove-file {
  background: transparent;
  border: none;
  color: #ff4444;
  cursor: pointer;
  padding: var(--space-1);
  border-radius: 50%;
  transition: all var(--transition-fast);
}

.remove-file:hover {
  background: rgba(255, 68, 68, 0.1);
}

.chat-interface {
  background: #2c2c3c;
  border: 1px solid #4b4b63;
  border-radius: var(--radius-md);
  padding: var(--space-4);
  margin-top: var(--space-4);
  display: flex;
  flex-direction: column;
  height: 500px;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  margin-bottom: var(--space-4);
  padding: var(--space-2);
  display: flex;
  flex-direction: column;
}

.message {
  margin-bottom: var(--space-3);
  padding: var(--space-3);
  border-radius: var(--radius-md);
  max-width: 80%;
  word-break: break-word;
}

.message p {
  margin: 0;
}

.system-message {
  background: #3a3a4a;
  align-self: flex-start;
  margin-right: auto;
}

.user-message {
  background: #4b4b8c;
  align-self: flex-end;
  margin-left: auto;
  text-align: right;
}

.error-message {
  background: #8c4b4b;
  align-self: flex-start;
  margin-right: auto;
}

.typing p {
  display: flex;
  align-items: center;
}

.typing p:after {
  content: '...';
  width: 1.5em;
  text-align: left;
  animation: typing 1.5s infinite;
}

@keyframes typing {
  0%, 100% { content: '.'; }
  33% { content: '..'; }
  66% { content: '...'; }
}

.chat-input-container {
  display: flex;
  gap: var(--space-2);
}

.chat-input {
  flex: 1;
  background: #3a3a4a;
  border: 1px solid #4b4b63;
  border-radius: var(--radius-sm);
  color: white;
  padding: var(--space-2);
  font-size: 1rem;
}

.chat-input:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.chat-send-btn {
  background: #7d86f3;
  color: white;
  border: none;
  border-radius: var(--radius-sm);
  padding: var(--space-2) var(--space-4);
  cursor: pointer;
  font-weight: bold;
  transition: background 0.2s ease;
}

.chat-send-btn:hover:not(:disabled) {
  background: #6a74e0;
}

.chat-send-btn:disabled {
  background: #5a5a7a;
  cursor: not-allowed;
  opacity: 0.7;
}

/* API Integration tab styling */
.api-endpoints-container {
  display: flex;
  flex-direction: column;
  gap: var(--space-5);
  margin-top: var(--space-4);
}

.api-endpoint {
  background: #2c2c3c;
  border: 1px solid #4b4b63;
  border-radius: var(--radius-md);
  padding: var(--space-4);
}

.api-endpoint h3 {
  color: #7d86f3;
  margin-top: 0;
  margin-bottom: var(--space-2);
}

.api-endpoint p {
  color: #bbb;
  margin-bottom: var(--space-3);
}

.code-block {
  position: relative;
  margin-top: var(--space-3);
  background: #20202d;
  border-radius: var(--radius-sm);
  padding: var(--space-3);
  overflow: auto;
}

.code-block pre {
  margin: 0;
  white-space: pre-wrap;
  word-break: break-all;
  color: #e6e6e6;
  font-family: 'Courier New', monospace;
  line-height: 1.5;
  font-size: 0.9rem;
}

.code-block button {
  position: absolute;
  top: var(--space-2);
  right: var(--space-2);
  background: #3a3a4a;
  color: white;
  border: none;
  border-radius: var(--radius-sm);
  padding: var(--space-1) var(--space-2);
  font-size: 0.8rem;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.code-block button:hover {
  opacity: 1;
}

/* Unified styling for special items */
.sandbox-nav-item {
  background-color: #2c2c3c;
  border: 1px solid #4b4b63;
  border-radius: var(--radius-md);
  margin: 8px 0;
  transition: var(--transition-normal);
}

.sandbox-nav-item:hover,
.sandbox-nav-item.active {
  background-color: #3b3b4f;
}

.sandbox-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px 8px;
}

.sandbox-icons {
  display: flex;
  gap: 12px;
  margin-bottom: 10px;
}

.sandbox-icons svg {
  font-size: 1.2rem;
  color: #b0b0b9;
}

.sandbox-title {
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 1.1rem;
  text-align: center;
  color: #ffffff;
}

.try-now-badge {
  background-color: #4b4b63;
  color: white;
  font-size: 0.8rem;
  padding: 4px 10px;
  border-radius: var(--radius-md);
  font-weight: 500;
}

.sandbox-nav-item:hover .sandbox-icons svg {
  color: #ffffff;
}

.sandbox-nav-item:hover .try-now-badge {
  background-color: var(--primary-light);
}

.sidebar-divider {
  height: 1px;
  background-color: #4b4b63;
  margin: 12px 0;
  width: 100%;
}

.sandbox-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 200px);
  max-height: 800px;
  background-color: #1e2028;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  margin-top: 20px;
  overflow: hidden;
  border: 1px solid #2c2f3c;
}

.chat-area {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: #1a1c23;
}

.message {
  margin-bottom: 15px;
  max-width: 85%;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.user-message {
  align-self: flex-end;
}

.assistant-message {
  align-self: flex-start;
}

.message-content {
  padding: 12px 16px;
  border-radius: 12px;
  line-height: 1.5;
  white-space: pre-wrap;
  font-size: 15px;
}

.user-message .message-content {
  background-color: #5664d2;
  color: white;
  border-bottom-right-radius: 4px;
  box-shadow: 0 2px 5px rgba(86, 100, 210, 0.3);
}

.assistant-message .message-content {
  background-color: #2c2f3c;
  color: #e6e8f0;
  border-bottom-left-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.message-content.loading {
  background-color: #252734;
  color: #a1a6c4;
  display: flex;
  align-items: center;
  gap: 8px;
  font-style: italic;
}

.chat-input-form {
  display: flex;
  padding: 15px 20px;
  background-color: #252734;
  border-top: 1px solid #32364a;
}

.chat-input {
  flex: 1;
  padding: 12px 15px;
  border: 1px solid #32364a;
  border-radius: 24px;
  font-size: 15px;
  outline: none;
  background-color: #1a1c23;
  color: #e6e8f0;
  transition: all 0.2s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1) inset;
}

.chat-input::placeholder {
  color: #6c7293;
}

.chat-input:focus {
  border-color: #5664d2;
  box-shadow: 0 0 0 3px rgba(86, 100, 210, 0.15);
}

.send-button {
  background-color: #5664d2;
  color: white;
  border: none;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  box-shadow: 0 2px 8px rgba(86, 100, 210, 0.4);
}

.send-button:hover:not(:disabled) {
  background-color: #4251bd;
  transform: translateY(-1px);
  box-shadow: 0 4px 10px rgba(86, 100, 210, 0.5);
}

.send-button:active:not(:disabled) {
  transform: translateY(0);
  box-shadow: 0 2px 5px rgba(86, 100, 210, 0.4);
}

.send-button:disabled {
  background-color: #3d4057;
  cursor: not-allowed;
  box-shadow: none;
}

/* Custom scrollbar for dark theme */
.chat-area::-webkit-scrollbar {
  width: 8px;
}

.chat-area::-webkit-scrollbar-track {
  background: #1a1c23;
}

.chat-area::-webkit-scrollbar-thumb {
  background-color: #32364a;
  border-radius: 4px;
}

.chat-area::-webkit-scrollbar-thumb:hover {
  background-color: #444964;
}

@media (max-width: 768px) {
  .sandbox-container {
    height: calc(100vh - 160px);
  }
  
  .message {
    max-width: 95%;
  }
  
  .message-content {
    font-size: 14px;
    padding: 10px 14px;
  }
  
  .chat-input {
    font-size: 14px;
    padding: 10px 14px;
  }
  
  .send-button {
    width: 38px;
    height: 38px;
  }
  
  .back-button {
    font-size: 14px;
  }
}

.back-button {
  position: absolute;
  left: 0;
  top: 0;
  background: none;
  border: none;
  color: #7d86f3;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: color 0.2s;
  padding: 8px 0;
}

.back-button:hover {
  color: #5664d2;
}

.page-header {
  position: relative;
  padding-top: 10px;
}

.model-info {
  background-color: #252734;
  padding: 15px 20px;
  border-bottom: 1px solid #32364a;
}

.model-badge {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;
}

.model-name {
  font-weight: 600;
  font-size: 14px;
  color: #e6e8f0;
}

.model-status {
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 12px;
  font-weight: 500;
}

.model-status.active {
  background-color: #1a3b2a;
  color: #4caf50;
}

.model-description {
  font-size: 14px;
  color: #a1a6c4;
  margin: 0;
  line-height: 1.4;
}

/* Add this to your existing CSS for the error banner */

.api-error-banner {
  background-color: #3d2525;
  border-bottom: 1px solid #542c2c;
  color: #f8d7da;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
}

.api-error-banner svg {
  color: #e74c3c;
}

.api-error-banner button {
  margin-left: auto;
  background: none;
  border: 1px solid #f8d7da;
  color: #f8d7da;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  transition: all 0.2s;
}

.api-error-banner button:hover {
  background-color: rgba(248, 215, 218, 0.1);
}